//@ts-check
import React from "react"
import Slider from "react-slick"
import { Flex } from "./Flex"
import { CustomBloc } from "./CustomBloc"
import { useStaticQuery, graphql } from "gatsby"
import { getTheme } from "../theme"
import { SubTitle } from "./SubTitle"
import { useIsTabletOrMobile } from "./CustomLayout"

export const MembersPreview = () => {
  const {
    prismicHomepageBody1MembreEquipe,
    prismicHomepage,
  } = useStaticQuery(graphql`
    query MembersPreviewQuery {
      prismicHomepageBody1MembreEquipe {
        items {
          member_description {
            html
          }
          member_full_name {
            text
          }
          member_picture {
            url
          }
        }
      }
      prismicHomepage {
        data {
          member_title {
            text
          }
        }
      }
    }
  `)

  const items = prismicHomepageBody1MembreEquipe.items
  const { member_title } = prismicHomepage.data

  const isMobile = useIsTabletOrMobile()

  // https://react-slick.neostack.com/docs/api#centerPadding
  const settings = {
    lazyLoad: "progressive",
    arrows: false,
    dots: true,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 5000,
    fadeIn: true,
    autoplay: true,
    pauseOnHover: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: getTheme().secondaryBackgroundColor,
      }}
    >
      <CustomBloc
        title={member_title.text}
        contentStyle={{ display: "unset" }}
        style={{
          width: "100vw",
          maxWidth: 1400,
          padding: "40px 10px",
          marginBottom: 5,
        }}
      >
        <Slider {...settings}>
          {items.map(
            (
              { member_picture, member_full_name, member_description },
              index
            ) => {
              return (
                <div style={{ margin: 10, width: 200 }} key={index}>
                  <Flex style={{ justifyContent: "center" }}>
                    <img
                      style={{ width: "150px" }}
                      src={member_picture.url}
                      alt={`${member_full_name}`}
                    />
                  </Flex>
                  <SubTitle
                    style={{
                      marginTop: 12,
                      fontSize: 21,
                      height: isMobile ? "auto" : 40,
                      textAlign: "center",
                    }}
                  >
                    {member_full_name.text}
                  </SubTitle>
                  <div
                    style={{
                      marginBottom: 10,
                      textAlign: "center",
                      maxHeight: "190px",
                      lineHeight: "20px",
                      overflow: "hidden",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: member_description.html,
                    }}
                  />
                </div>
              )
            }
          )}
        </Slider>
      </CustomBloc>
    </div>
  )
}
