//@ts-check
import React from "react"
import { Flex } from "./Flex"
import { CustomBloc } from "./CustomBloc"
import { useIsTabletOrMobile } from "./CustomLayout"
import { graphql } from "gatsby"
import { useStaticQuery } from "gatsby"

export const Location = () => {
  const { prismicHomepage } = useStaticQuery(graphql`
    query LocationQuery {
      prismicHomepage {
        data {
          acces_description {
            html
          }
        }
      }
    }
  `)

  const { acces_description } = prismicHomepage.data
  const isMobile = useIsTabletOrMobile()
  return (
    <CustomBloc title="Accès">
      <div style={{ width: "100%" }}>
        <Flex
          style={{
            justifyContent: "center",
            width: "100%",
            flexDirection: isMobile ? "column" : "column",
          }}
        >
          <Flex style={{ flexDirection: "column" }}>
            <Flex style={{ justifyContent: "center" }}>
              <div
                dangerouslySetInnerHTML={{ __html: acces_description.html }}
                style={{ maxWidth: 900, marginTop: 10 }}
              />
            </Flex>
            <div style={{ marginTop: 20 }}>
              <a
                href="https://citymapper.com/go/tb5pekyur6"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src="https://static.citymapper.com/img/embed/GetMeThere_Citymapper.png"
                  alt="Get directions with Citymapper"
                />
              </a>
            </div>
            <iframe
              title="Maps Clinique Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d83845.07819208915!2d2.440302278969607!3d48.95046406377517!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6151912925d6f%3A0x3d6739032031f494!2sClinique%20Ophtalmologique%20Belle%20Etoile!5e0!3m2!1sfr!2sfr!4v1577661171402!5m2!1sfr!2sfr"
              width={isMobile ? "100%" : "80%"}
              height="450"
              style={{ marginTop: 20 }}
            ></iframe>
          </Flex>
        </Flex>
      </div>
    </CustomBloc>
  )
}
