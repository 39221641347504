//@ts-check
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getTheme } from "../theme"
import { CustomBloc } from "./CustomBloc"
import { useIsTabletOrMobile } from "./CustomLayout"

export const Citations = () => {
  const { prismicHomepageBodyCitation } = useStaticQuery(graphql`
    query CitationsQuery {
      prismicHomepageBodyCitation {
        items {
          citation {
            text
          }
          nom_prenom {
            text
          }
        }
      }
    }
  `)

  const isMobile = useIsTabletOrMobile()
  const items = isMobile
    ? prismicHomepageBodyCitation.items.slice(0, 2)
    : prismicHomepageBodyCitation.items
  const QUOTE_STYLE = {
    color: getTheme().subTitleColor,
    fontStyle: "initial",
    fontSize: 28,
    overflow: "auto",
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: getTheme().titleColor,
      }}
    >
      <div style={{ maxWidth: "85vw" }}>
        <CustomBloc
          style={{
            color: getTheme().onButtonColor,
            backgroundColor: getTheme().titleColor,
            overflow: "overlay",
            maxWidth: 1200,
          }}
        >
          {items.map((item, index) => {
            return (
              <div
                style={{
                  fontSize: 18,
                  padding: "2% 6%",
                  fontFamily: "Signika",
                  width: `${100 / items.length}%`,
                }}
                key={index}
              >
                <div style={{ fontSize: 15 }}>{item.nom_prenom.text}</div>
                <div style={{ marginTop: 10 }} />
                <div style={{ fontStyle: "italic" }}>
                  <span style={QUOTE_STYLE}>“</span>
                  <br />
                  <div
                    style={{
                      marginTop: -11,
                      lineHeight: "30px",
                    }}
                  >
                    {item.citation.text}
                    <span
                      style={{
                        marginLeft: 8,
                        ...QUOTE_STYLE,
                      }}
                    >
                      ”
                    </span>
                  </div>
                </div>
              </div>
            )
          })}
        </CustomBloc>
      </div>
    </div>
  )
}
