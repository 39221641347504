//@ts-check

import React from "react"

import { Flex } from "./Flex"
import { SubTitle } from "./SubTitle"
import { Text } from "./Text"
import Image from "./image"
import { getTheme } from "../theme"

//Could be linked to the banner with caption slice
export const ActuComponent = ({
    isMobile = false,
    data,
    publishDateString
}) => {
    const lineHeight = isMobile ? 20 : 21
    return (
        <Flex
            style={{
                justifyContent: "center",
                width: "100%",
                flexDirection: "column",
                ...(isMobile ? { margin: "10px 0px 20px 0px" } : { margin: "10px 0px" }),
            }}
        >
            <Flex
                style={{
                    lineHeight: `${lineHeight}px`,
                    margin: "10px 0",
                    width: isMobile ? "90%" : "80%",
                    borderRadius: '10px',
                    padding: "20px",
                    alignItems: 'flexStart',
                    flexDirection: "column",
                    backgroundColor: getTheme().secondaryBackgroundColor,
                    // ** play with these values very cautiously with same comment values in paralell 
                    minHeight: isMobile ? `${350}px` : `${212}px`,
                }}>


                {!!data.title_of_banner && !!data.title_of_banner.text &&
                    <Flex style={{ marginBottom: '10px', alignItems: 'unset', flexDirection: "column" }}>
                        {!!data.title_of_banner && !!data.title_of_banner.text &&
                            <SubTitle style={{ marginBottom: 0 }}>
                                {data.title_of_banner.text}
                            </SubTitle>}
                        {!!publishDateString &&
                            <Text style={{ marginTop: '2px', fontStyle: 'italic' }}>
                                {publishDateString}
                            </Text>}
                    </Flex>}
                <Flex style={isMobile ? { flexDirection: 'column' } : {}}>
                    {data.image_banner.fluid && <div style={isMobile ? { marginBottom: '10px' } : { marginRight: '10px' }}>
                        <Image
                            style={{
                                // @ts-ignore
                                maxWidth: "200px",
                                width: "30vw"
                            }}
                            fluid={data.image_banner.fluid} />
                    </div>}
                    <Flex style={{ justifyContent: "center", color: getTheme().textColor, fontSize: '16px', marginTop: 5 }}>
                        <div
                            dangerouslySetInnerHTML={{ __html: data.description.html }} />
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}