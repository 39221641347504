//@ts-check

import { Icon } from "antd"
import { Flex } from "./Flex"
import { getTheme } from "../theme"
// @ts-ignore
import ArrowDown from "../assets/arrow_down.svg"

export const ArrowDownButton = ({
    textContent = '',
    containerStyle = {},
    arrowStyle = {},
    onClick = null
}) => {
    return (
        <Flex
            style={{
                fontSize: 14,
                fontWeight: 600,
                textTransform: "uppercase",
                flexDirection: "column",
                color: getTheme().buttonTernaryColor,
                ...containerStyle
            }}
        >
            {textContent}
            <Icon
                //
                style={{
                    fill: getTheme().buttonTernaryColor,
                    marginTop: 10,
                    fontSize: 22,
                    ...arrowStyle
                }}
                onClick={onClick}
                component={ArrowDown}
            />
        </Flex>
    )
}