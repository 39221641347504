// @ts-check
import React from "react"
import { Icon } from "antd"
import { Flex } from "../Flex"
import { getTheme } from "../../theme"
// @ts-ignore
import ArrowRight from "../../assets/arrow_right.svg"

export const ButtonTernary = ({
  onClick,
  label = "",
  style = {},
  iconStyle = {},
  flexStyle = {},
  onMouseEnter = () => { },
  onMouseLeave = () => { },
}) => (
  <div style={style} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} >
    <a onClick={onClick}>
      <Flex
        style={{
          fontSize: 14,
          fontWeight: 600,
          textTransform: "uppercase",
          color: getTheme().buttonTernaryColor,
          justifyContent: "flex-end",
          ...flexStyle,
        }}
      >
        {label}
        <Icon
          style={{
            marginLeft: 5,
            fontSize: 14,
            stroke: getTheme().buttonTernaryColor,
            ...iconStyle,
          }}
          component={ArrowRight}
        />
      </Flex>
    </a>
  </div>
)
