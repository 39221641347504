//@ts-check
import React from "react"
import { Title } from "./Title"
import { getTheme } from "../theme"

export const SubTitle = ({ children, style = {}, hasDot = false }) => (
  <Title
    style={{
      fontWeight: 400,
      fontFamily: "Futura",
      color: getTheme().subTitleColor,
      ...style,
    }}
    hasDot={hasDot}
    level={3}
  >
    {children}
  </Title>
)
