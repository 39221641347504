//@ts-check
import React from "react"
import { Flex } from "./Flex"
import { CustomBloc } from "./CustomBloc"
import { useStaticQuery, graphql } from "gatsby"
import { Text } from "./Text"
import { Title } from "./Title"
import { ButtonRDV } from "./buttonRDV"

export const RDV = () => {
  const { prismicHomepage } = useStaticQuery(graphql`
    query RDVQuery {
      prismicHomepage {
        data {
          rdv_image {
            url
          }
          rdv_title {
            text
          }
          rdv_description {
            text
          }
        }
      }
    }
  `)

  const { rdv_image, rdv_title, rdv_description } = prismicHomepage.data

  return (
    <div
      style={{
        width: "100%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        display: "flex",
        justifyContent: "center",
        backgroundImage: `url(${rdv_image.url})`,
      }}
    >
      <div style={{ width: "85vw", maxWidth: "1200px" }}>
        <CustomBloc
          style={{
            padding: "40px 0px",
            maxWidth: 1200,
          }}
        >
          <Flex
            style={{
              flexDirection: "column",
            }}
          >
            <Title style={{ textTransform: "uppercase" }}>
              {rdv_title.text}
            </Title>
            <Text>{rdv_description.text}</Text>
            <div style={{ marginTop: 10 }}>
              <ButtonRDV />
            </div>
          </Flex>
        </CustomBloc>
      </div>
    </div>
  )
}
