//@ts-check
import React from "react"
import { Flex } from "./Flex"
import { useIsTabletOrMobile, getIsIos } from "./CustomLayout"
import { ArrowDownButton } from "./ArrowDownButton"


export const BigMediaContainer = ({
  imageUrl = "",
  videoUrl = "",
  ExtraTop = "",
}) => {
  const isTabletOrMobile = useIsTabletOrMobile()
  const isIos = getIsIos()

  const IMG_STYLE_PARALLAX = {
    backgroundImage: `url(${imageUrl})`,
    backgroundAttachment: isIos ? "scroll" : "fixed", // Parallax not working on ios + safari
    backgroundPosition: "center",
    backgroundRepeat: " no-repeat",
    backgroundSize: "cover",
  }

  const MEDIA_DESKTOP_STYLE = {
    minWidth: "100%",
    minHeight: "calc(100vh - 160px)",
    maxHeight: "85vh",
    objectFit: "cover",
  }

  const MEDIA_MOBILE_STYLE = {
    minHeight: "80vh",

    objectFit: "cover",
    width: "100%",
    maxHeight: "120vh",
  }

  return (
    <div style={{ zIndex: 1, position: "relative" }}>
      {videoUrl ? (
        <video
          preload="metadata"
          // @ts-ignore
          muted
          autoPlay
          loop
          playsInline
          // poster={imageUrl}
          // @ts-ignore
          style={{
            ...(isTabletOrMobile ? MEDIA_MOBILE_STYLE : MEDIA_DESKTOP_STYLE),
          }}
        >
          <source src={videoUrl + "?autoplay=1&loop=1"} type="video/mp4" />
        </video>
      ) : (
          <div
            // @ts-ignore
            style={{
              ...(isTabletOrMobile ? MEDIA_MOBILE_STYLE : MEDIA_DESKTOP_STYLE),
              ...IMG_STYLE_PARALLAX,
            }}
          />
        )}
      {!!ExtraTop && (
        <Flex
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            padding: "5%",
            width: "100%",
            zIndex: 2,
          }}
        >
          <div
            style={{
              maxHeight: "100%",
              position: "relative",
              width: "100%",
            }}
          >
            {
              // @ts-ignore
              <ExtraTop />
            }
          </div>
        </Flex>
      )}
      {!isTabletOrMobile && (
        <div
          style={{
            zIndex: 2,
            minWidth: 1300,
            position: "relative",
            top: "-80px",
          }}
        >
          <ArrowDownButton
            textContent="découvrir"
          />
        </div>
      )}
    </div>
  )
}
