//@ts-check
import React from "react"
import { Flex } from "./Flex"
import { Title } from "./Title"
import { useIsTabletOrMobile } from "./CustomLayout"
import { useStaticQuery, graphql } from "gatsby"
import { Text } from "./Text"
import { getTheme } from "../theme"
import { ButtonRDV } from "./buttonRDV"
import { BigMediaContainer } from "./BigMediaContainer"

export const MediaHome = () => {
  const { prismicHomepage } = useStaticQuery(graphql`
    query VideoQuery {
      prismicHomepage {
        data {
          title {
            text
          }
          description {
            text
          }
          title_mobile {
            text
          }
          description_mobile {
            text
          }
          image {
            url
          }
          video {
            url
          }
        }
      }
    }
  `)

  const {
    title,
    title_mobile,
    description,
    description_mobile,
    image,
    video,
  } = prismicHomepage.data

  const isTabletOrMobile = useIsTabletOrMobile()

  return (
    <BigMediaContainer
      imageUrl={image.url}
      videoUrl={video.url}
      // @ts-ignore
      ExtraTop={() => (
        <>
          <Title
            style={{
              fontSize: isTabletOrMobile ? "2em" : "3vw",
              padding: isTabletOrMobile ? "0 10%" : "default",
              ...(isTabletOrMobile ? {} : { marginLeft: 70 }),

              textAlign: isTabletOrMobile ? "center" : "left",
              maxWidth: isTabletOrMobile ? "default" : "35vw",
              textTransform: isTabletOrMobile ? "uppercase" : "inherit",

              minWidth: 300,
            }}
          >
            {isTabletOrMobile ? title_mobile.text : title.text}
          </Title>
          <Text
            style={{
              fontSize: isTabletOrMobile ? "1.5em" : "1.4vw",
              color: isTabletOrMobile ? getTheme().subTitleColor : "inherit",
              ...(isTabletOrMobile ? {} : { marginLeft: 70 }),

              textAlign: isTabletOrMobile ? "center" : "left",
              maxWidth: isTabletOrMobile ? "default" : "40vw",
              textTransform: isTabletOrMobile ? "uppercase" : "inherit",
              minWidth: 300,
              display: "block",
            }}
          >
            {isTabletOrMobile ? description_mobile.text : description.text}
          </Text>
          <Flex
            style={{
              marginTop: isTabletOrMobile ? "40px" : "50px",
              flexDirection: isTabletOrMobile ? "column" : "row",
              ...(isTabletOrMobile ? {} : { marginLeft: 70 }),
            }}
          >
            <ButtonRDV />
          </Flex>
        </>
      )}
    />
  )
}
