//@ts-check

import React from "react"
import { graphql } from "gatsby"
import { useStaticQuery } from "gatsby"
import { Date } from 'prismic-reactjs'
import { useIsMobile } from "./CustomLayout"
import { ActuComponent } from "./ActuComponent"
import { getTheme } from "../theme"
import { CustomBloc } from "./CustomBloc"
import Slider from "react-slick"

const settings = {
  lazyLoad: 'progressive',
  arrows: false,
  infinite: true,
  speed: 1500,
  autoplaySpeed: 10000,
  dots: false,
  fadeIn: true,
  autoplay: true,
  pauseOnHover: false,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1050,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

export const Actus = ({ style = {} }) => {
  const isMobile = useIsMobile()
  const { allPrismicActuBodyBannerWithCaption } = useStaticQuery(graphql`
  query ActusQuery {
    allPrismicActuBodyBannerWithCaption(sort: {order: DESC, fields: primary___date_de_publication}, limit: 5) {
      nodes {
        primary {
          description {
            html
          }
          image_banner {
            fluid(maxWidth: 1240) {
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
              aspectRatio
             }
          }
          title_of_banner {
            text
          }
          date_de_publication(locale: "fr-FR")
        }
      }
    }
  }
  `)

  const nodes = allPrismicActuBodyBannerWithCaption.nodes
    .map((node) => {
      const publishDate = node.primary.date_de_publication ? Date(node.primary.date_de_publication) : null
      const publishDateString = publishDate ? Intl.DateTimeFormat('fr-FR', {
        year: 'numeric',
        month: 'short',
        day: '2-digit'
      }).format(publishDate) : ""
      return { ...node, publishDateString }
    })

  if (nodes.length === 0) {
    return null
  }

  return (
    <CustomBloc style={{ ...style, paddingTop: isMobile ? 15 : 0, paddingBottom: isMobile ? 30 : 0, backgroundColor: getTheme().primaryBackgroundColor }}
      hasDot={true}
      title="Notre actualité"
      contentStyle={{ maxWidth: 1240, display: 'unset' }}>
      <Slider {...settings}>
        {nodes.map(({ primary, publishDateString }, index) => {
          return (
            <div key={`actu-${index}`}  >
              <ActuComponent
                isMobile={isMobile}
                data={primary}
                publishDateString={publishDateString}
              />
            </div>
          )
        }
        )}
      </Slider >
    </CustomBloc >
  )
}
