/* eslint-disable react/display-name */
//@ts-check
import React from "react"
import { MediaHome } from "../components/MediaHome"
import SEO from "../components/seo"
import { CenterPreview } from "../components/CenterPeview"
import { RDV } from "../components/RDV"
import { Citations } from "../components/Citations"
import { Location } from "../components/Location"
import { MembersPreview } from "../components/MembersPreview"
import { Actus } from "../components/Actus"
import { useIsTabletOrMobile } from "../components/CustomLayout"
// import { withPreviewResolver } from "gatsby-source-prismic"

// const { linkResolver } = require("../utils/linkResolver")


const Home = () => {
  const isMobile = useIsTabletOrMobile()
  return (
    <>
      {/* TODO use param for seo */}
      <SEO />
      <MediaHome />
      <CenterPreview style={{ marginTop: isMobile ? 0 : -25 }} />
      <RDV />
      <MembersPreview />
      <Citations />
      <Actus style={{ marginTop: isMobile ? 0 : 20 }} />
      <Location />
    </>
  )
}

export default Home

// export default withPreviewResolver(Home, {
//   repositoryName: process.env.PRISMIC_REPOSITORY_NAME,
//   // @ts-ignore
//   linkResolver,
// })
