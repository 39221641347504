//@ts-check
import React from "react"
import { Flex } from "./Flex"
import { Row } from "antd"
import { useStaticQuery, graphql, navigate } from "gatsby"
import Image from "./image"
import { SubTitle } from "./SubTitle"
import { ButtonTernary } from "./ButtonTernary/ButtonTernary"
import { useIsMobile } from "./CustomLayout"
import { useMediaQuery } from "react-responsive"
import { getTheme } from "../theme"
import { CustomBloc } from "./CustomBloc"

export const CenterPreview = ({ style = {} }) => {
  const { prismicCenterpreview } = useStaticQuery(graphql`
    query CenterpreviewQuery {
      prismicCenterpreview {
        id
        data {
          description1 {
            html
          }
          description2 {
            html
          }
          image1 {
            url
          }
          image2 {
            url
          }
          image3 {
            url
          }
          title1 {
            text
          }
          title2 {
            text
          }
          link1 {
            url
          }
          link2 {
            url
          }
        }
      }
    }
  `)

  const {
    title1,
    title2,
    description1,
    description2,
    link1,
    link2,
    image1,
    image2,
    image3,
  } = prismicCenterpreview.data

  const imgStyle = { height: "330px", objectFit: "cover" }
  const flexStyle = {
    width: "44%",
    margin: "0 2%",
    justifyContent: "center",
    flexWrap: "wrap",
  }
  const mobileStyle = { width: "100%", margin: "0 3%" }
  const buttonStyle = { marginTop: 30, marginRight: 5 }
  const isMobile = useIsMobile()
  const isMoreThan1200 = useMediaQuery({ query: "(min-width: 1200px)" })
  const isMoreThan900 = useMediaQuery({ query: "(min-width: 900px)" })
  return (
    <div
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        backgroundColor: getTheme().primaryBackgroundColor,
        ...(isMobile
          ? { padding: "0px 30px 60px 30px" }
          : { padding: "35px 50px" }),
      }}
    >
      <CustomBloc
        style={{
          width: "85vw",
          maxWidth: 1200,
          ...(isMobile ? {} : { padding: 0 })
        }}
        title={"Nos centres"}
        hasDot={true}
      >
        <Row
          style={{ display: "flex", alignItems: "center", minHeight: "40vw" }}
        >
          {!isMobile && (
            <Flex style={flexStyle}>
              <Image
                style={{ ...imgStyle, marginTop: -150 }}
                src={image1.url}
              />
              {isMoreThan900 && (
                <Image
                  style={{
                    ...imgStyle,
                    marginTop: -30,
                    marginLeft: 15,
                    marginRight: 15,
                  }}
                  src={image2.url}
                />
              )}
              {isMoreThan1200 && (
                <Image
                  style={{ ...imgStyle, marginTop: -100 }}
                  src={image3.url}
                />
              )}
            </Flex>
          )}
          <div style={isMobile ? mobileStyle : flexStyle}>
            <div style={{ marginTop: 20 }} />
            <SubTitle>{title1.text}</SubTitle>
            <div
              style={{ fontSize: 16 }}
              dangerouslySetInnerHTML={{ __html: description1.html }}
            />
            <ButtonTernary
              onClick={() => navigate(link1.url)}
              label="En savoir plus"
              style={buttonStyle}
            />
            <div style={{ marginTop: 60 }} />
            <SubTitle>{title2.text}</SubTitle>
            <div
              style={{ fontSize: 16 }}
              dangerouslySetInnerHTML={{ __html: description2.html }}
            />
            <ButtonTernary
              onClick={() => navigate(link2.url)}
              label="En savoir plus"
              style={buttonStyle}
            />
          </div>
        </Row>
      </CustomBloc>
    </div>
  )
}
